import PropTypes from "prop-types"
import React, { useState } from "react"
import Loadable from "@loadable/component"
// Style Imports
import "./Accordion.scss"
// Content Imports
const ArrowIcon = Loadable(() => import('@components/content/icons/Arrow.jsx'))

function Accordion({ hidden, title, addedClass, children }) {
    const [expanded, setExpanded] = useState(!hidden)

    return (
        <div className={"accordion " + (expanded ? "accordion--open" : "") + (addedClass ? addedClass : "")} role="tablist">
            <div className="accordion__label" role="tab">
                <h4>
                    <button
                        className="accordion__btn"
                        aria-expanded={expanded}
                        onClick={() => {
                            setExpanded(!expanded)
                        }}
                    >
                        <span className="sr-only">{!expanded ? "expand" : "collapse"}</span>
                        <span className="accordion__icon">
                            <ArrowIcon />
                        </span>
                        <span className="accordion__heading">{title}</span>
                    </button>
                </h4>
            </div>
            <div className="accordion__content" hidden={!expanded} aria-hidden={!expanded}>
                {children}
            </div>
        </div>
    )
}

export default Accordion

Accordion.propTypes = {
    title: PropTypes.string,
    hidden: PropTypes.bool,
    addedClass: PropTypes.string,
}

Accordion.defaultProps = {
    title: null,
    hidden: true,
    addedClass: '',
}
