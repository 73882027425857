import React from 'react';
import PropTypes from 'prop-types';
import Loadable from "@loadable/component"
import { Link } from "gatsby"
// Style Imports
import "./CTA.scss"
// Content imports
const DoubleArrowIcon = Loadable(() => import('@components/content/icons/DoubleArrow.jsx'))
const CircleArrowIcon = Loadable(() => import('@components/content/icons/CircleFilledArrow.jsx'))

const CTA = ({
	children,
	url,
	isCircleArrow,
}) => {
	return (
        <div className={"cta " + (isCircleArrow ? 'circle-icon' : '')}>
            <Link to={url}>{children}{ isCircleArrow ? <CircleArrowIcon  /> : <DoubleArrowIcon  />}</Link>
        </div>
    )
}

CTA.defaultProps = {
	children: null,
	url: '',
	isCircleArrow: false,
};

CTA.propTypes = {
	children: PropTypes.node,
	url: PropTypes.string.isRequired,
	isCircleArrow: PropTypes.bool,
};

export default CTA;
