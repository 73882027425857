import React from 'react';
import { Link } from 'gatsby';
import Loadable from '@loadable/component';
// Style Imports
import './styles.scss';
import '@components/content/cta/CTA';
// SEO
import Seo from '@components/utility/SEO';
// Structural Imports
import Layout from '@components/structure/Layout.jsx';
// Content Imports
import Anchor from '@components/content/anchor.jsx';
import Accordion from '@components/content/accordion/Accordion.jsx';
import Hero from '@components/content/hero/Hero.jsx';
import Image from '@components/content/fractals-image.jsx';
import PatientFeedbackResources from '@components/content/patientFeedback/page-resources/PatientFeedbackResources';
const StethoscopeLargeIcon = Loadable(() =>
	import('@components/content/icons/StethoscopeLarge.jsx')
);

const resources = () => (
	<Layout addedClass='page--resources'>
		<Seo
			title='Patient Resources | LUPKYNIS&reg; (voclosporin)'
			description='Get answers to your questions about LUPKYNIS and lupus nephritis. Learn more about Aurinia Alliance® and sign up for updates from LUPKYNIS. See full Safety and Prescribing Information, and BOXED WARNING.'
			canonicalURL='https://www.lupkynis.com/resources'
		/>
		<Hero addedClass='bg-yellow hero--string'>
			<div className='col-wrapper'>
				<div className='container'>
					<div className='hero__content'>
						<h1 className='heading heading--accent'>Resources</h1>
						<h2>
							Never <span>fight</span> alone
						</h2>
						<p>
							Your{' '}
							<Anchor url='https://www.auriniaalliance.com' target='_blank'>
								Aurinia Alliance<sup>&reg;</sup>
							</Anchor>{' '}
							team focuses on providing you with what you need every step of
							the way on your LUPKYNIS journey. We understand that your
							lupus nephritis journey is unique and we are here to listen
							and support you along the way. Your team includes one-on-one
							support from a dedicated Nurse Case Manager who will check in
							with you on a regular basis to answer your questions and see
							how you are doing.
						</p>
					</div>
					<div className='hero__image'>
						<Image
							data={{
								desktopSrc: 'resources/hero.png',
								mobileSrc: 'resources/hero--mobile.png',
								altText: 'Image of Crystal S., RN, a Nurse Case Manager',
							}}
						/>
					</div>
				</div>
			</div>
			<div className='hero__offers'>
				<div className='container'>
					<h3>Aurinia Alliance offers:</h3>
					<div className='hero__offers__allOffers'>
						<div className='hero__offers__allOffers_1'>
							<div className='hero__offers__icon__container'>
								<img
									src='/images/resources/resources-icon-1.svg'
									alt=''
									srcset=''
								/>
							</div>
							<p>Educational tools</p>
						</div>

						<div className='hero__offers__allOffers_2'>
							<div className='hero__offers__icon__container'>
								<img
									src='/images/resources/resources-icon-2.svg'
									alt=''
									srcset=''
								/>
							</div>
							<p>
								A specialty pharmacy that will deliver LUPKYNIS directly
								to you
							</p>
						</div>

						<div className='hero__offers__allOffers_3'>
							<div className='hero__offers__icon__container'>
								<img
									src='/images/resources/resources-icon-3.svg'
									alt=''
									srcset=''
								/>
							</div>
							<p>Help understanding your insurance and funding options</p>
						</div>

						<div className='hero__offers__allOffers_4'>
							<div className='hero__offers__icon__container'>
								<img
									src='/images/resources/resources-icon-4.svg'
									alt=''
									srcset=''
								/>
							</div>
							<p>$0 copay for eligible patients</p>
						</div>
					</div>

					<div className='hero__offers__bottomNotes'>
						<h4>
							97% of the time*, patients paid less than $10 for their
							LUPKYNIS prescription.
						</h4>
						<p>
							*97% of the shipments of LUPKYNIS delivered cost the patient
							$10 or less. These are patients on commercial insurance,
							Medicaid or Medicare from January 2022 to December 2022
						</p>
						<h5>
							Learn more about Aurinia Alliance and sign up to be assigned a
							Nurse Case Manager and start receiving personalized support.
						</h5>
					</div>
				</div>
			</div>
			<div className='hero__footer text-center'>
				<div className='container'>
					<div>
						<Link to='/registration' className='button'>
							Sign up for more info
						</Link>
					</div>
					<div className='hero__footer__right'>
						<div className='cta'>
							<p>
								Or call{' '}
								<Anchor url='tel:18332874642'>
									1-833-AURINIA
									<br className='mobile-only' /> (1-833-287-4642)
								</Anchor>
								&nbsp;to&nbsp;enroll today
							</p>
						</div>
						<p className='bottom__note'>
							Don't forget to save this number to your phone contacts so you
							can easily reach us when you need support.
						</p>
					</div>
				</div>
			</div>
		</Hero>
		<section id='ConnectWithSomeone'>
			<div className='container'>
				<div className='ConnectWithSomeone_Content'>
					<h3>Connect with someone who's been there</h3>
					<p>
						<strong>
							Lupus Nephritis Peer Connect<sup>TM</sup>
						</strong>{' '}
						is a mentorship program that allows you to speak with someone who
						may understand what you're going through. Our peer mentors
						volunteer for one-on-one calls to answer your questions, offer
						encouragement, and share their own personal experiences with lupus
						nephritis and treatment with LUPKYNIS.
					</p>
					<Anchor
						url='https://www.lnpeerconnect.com/'
						target='_blank'
						linkClass='button'>
						Learn More
					</Anchor>
				</div>
			</div>
		</section>
		<PatientFeedbackResources />
		<section id='NextAppointment' className='bg-purple text-center'>
			<div className='container container--narrow'>
				<div className='icon'>
					<StethoscopeLargeIcon />
				</div>
				<h2>Feel prepared with our doctor discussion guide</h2>
				<p>
					Our downloadable doctor discussion guide can help you start the
					conversation and confidently ask your doctor questions about LUPKYNIS.
					Make sure to download and bring it with you to your next appointment.
				</p>
				<div>
					<Anchor
						url='/pdfs/doctor-discussion-guide.pdf'
						target='_blank'
						linkClass='button'>
						Download the guide
					</Anchor>
				</div>
			</div>
		</section>
		<section id='Organizations' className='bg-white'>
			<div className='container container--narrow'>
				<h2>Organizations leading the way</h2>
				<p>
					Connecting with peer communities and support networks are a great way
					to get involved, from raising awareness about lupus nephritis to
					helping you feel connected and supported by others who are fighting
					the same fight.
				</p>
				<p>
					Please note that we do not endorse or monitor the activities and
					content of advocacy groups or their websites.
				</p>
				<div className='col-wrapper'>
					<div className='container'>
						<div>
							<h3>Regional and local organizations:</h3>
							<ul className='resource-list'>
								<li>
									<Anchor
										url='https://kaleidoscopefightinglupus.org/'
										target='_blank'
										requiresExitModal={true}
										relationship='noopener noreferrer'>
										Kaleidoscope Fighting Lupus
									</Anchor>
								</li>
								<li>
									<Anchor
										url='https://looms4lupus.org/'
										target='_blank'
										requiresExitModal={true}
										relationship='noopener noreferrer'>
										Looms for Lupus
									</Anchor>
								</li>
								<li>
									<Anchor
										url='https://lupusne.org/'
										target='_blank'
										requiresExitModal={true}
										relationship='noopener noreferrer'>
										Lupus Foundation New England
									</Anchor>
								</li>
								<li>
									<Anchor
										url='https://lupusla.org/'
										target='_blank'
										requiresExitModal={true}
										relationship='noopener noreferrer'>
										Lupus LA
									</Anchor>
								</li>
								<li>
									<Anchor
										url='https://www.lupusil.org/'
										target='_blank'
										requiresExitModal={true}
										relationship='noopener noreferrer'>
										Lupus Society of Illinois
									</Anchor>
								</li>
								<li>
									<Anchor
										url='https://nwkidneycouncil.org/'
										target='_blank'
										requiresExitModal={true}
										relationship='noopener noreferrer'>
										Northwest Kidney Council
									</Anchor>
								</li>
								<li>
									<Anchor
										url='https://www.lupus.org/chapters'
										target='_blank'
										requiresExitModal={true}
										relationship='noopener noreferrer'>
										Regional Lupus Foundation Chapters
									</Anchor>
								</li>
							</ul>
						</div>
						<div>
							<h3>
								National <br className='desktop-only' />
								organizations:
							</h3>
							<ul className='resource-list'>
								<li>
									<Anchor
										url='https://aakp.org/'
										target='_blank'
										requiresExitModal={true}
										relationship='noopener noreferrer'>
										American Association of Kidney Patients
									</Anchor>
								</li>
								<li>
									<Anchor
										url='https://www.kidneyfund.org/'
										target='_blank'
										requiresExitModal={true}
										relationship='noopener noreferrer'>
										American Kidney Fund
									</Anchor>
								</li>
								<li>
									<Anchor
										url='https://www.hss.edu/charladelupus.asp'
										target='_blank'
										requiresExitModal={true}
										relationship='noopener noreferrer'>
										Charla de Lupus (Lupus Chat)
									</Anchor>
								</li>
								<li>
									<Anchor
										url='https://www.ladainc.org/'
										target='_blank'
										requiresExitModal={true}
										relationship='noopener noreferrer'>
										Lupus &amp; Allied Diseases Association
									</Anchor>
								</li>
								<li>
									<Anchor
										url='https://www.lupuschat.org/'
										target='_blank'
										requiresExitModal={true}
										relationship='noopener noreferrer'>
										Lupus Chat
									</Anchor>
								</li>
								<li>
									<Anchor
										url='https://www.lupus.org/'
										target='_blank'
										requiresExitModal={true}
										relationship='noopener noreferrer'>
										Lupus Foundation of America
									</Anchor>
								</li>
								<li>
									<Anchor
										url='https://www.lupusresearch.org/'
										target='_blank'
										requiresExitModal={true}
										relationship='noopener noreferrer'>
										Lupus Research Alliance
									</Anchor>
								</li>
								<li>
									<Anchor
										url='https://www.kidney.org/'
										target='_blank'
										requiresExitModal={true}
										relationship='noopener noreferrer'>
										National Kidney Foundation
									</Anchor>
								</li>
								<li>
									<Anchor
										url='https://nephcure.org/'
										target='_blank'
										requiresExitModal={true}
										relationship='noopener noreferrer'>
										Nephcure Kidney International
									</Anchor>
								</li>
								<li>
									<Anchor
										url='https://thelupusinitiative.org/'
										target='_blank'
										requiresExitModal={true}
										relationship='noopener noreferrer'>
										The Lupus Initiative
									</Anchor>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div className='container'>
				<hr />
			</div>
		</section>
		<section id='FAQ'>
			<div className='container container--narrow'>
				<h2>Frequently asked questions</h2>
				<div className='accordions'>
					<h3>What is lupus nephritis?</h3>
					<Accordion title='What is lupus nephritis?'>
						<p>
							Lupus nephritis is an inflammation of the kidneys caused by
							lupus. With lupus nephritis, kidneys lose the ability to
							function properly to remove waste from the blood and control
							the fluids in your body. The disease can lead to serious
							health problems, permanent kidney damage, and even kidney
							failure.
						</p>
					</Accordion>
					<Accordion title='What are the symptoms of lupus nephritis?'>
						<p>
							Early signs and symptoms of lupus nephritis can be subtle or
							inconsistent and often overlap with other diseases so there
							may be few signs that anything is wrong. But common signs and
							symptoms include swelling (usually in the legs, feet, or
							ankles), foamy, frothy, or sudsy urine, hematuria (blood in
							urine), and protein in the urine (called proteinuria). If you
							think you are experiencing symptoms of lupus nephritis, talk
							to your doctor.
						</p>
					</Accordion>
					<Accordion title='How is lupus nephritis diagnosed?'>
						<p>
							A lupus nephritis diagnosis is usually made by a nephrologist
							or a rheumatologist. It is often diagnosed through use of a
							urine test (to look for protein or blood in the urine), a
							blood test, and/or a kidney biopsy. Talk to your doctor about
							routine urine and blood testing, which can help catch lupus
							nephritis early and start to determine a treatment plan.
						</p>
					</Accordion>
				</div>
				<div className='accordions'>
					<h3>What is lupkynis?</h3>
					<Accordion title='What is LUPKYNIS?'>
						<p>
							LUPKYNIS is the first FDA-approved oral medication
							specifically for adults with active lupus nephritis.
						</p>
					</Accordion>
					<Accordion title='Who should take LUPKYNIS?'>
						<p>
							LUPKYNIS is indicated for use in adults with active lupus
							nephritis, but not everyone with lupus nephritis should take
							LUPKYNIS. Talk to your doctor to see if LUPKYNIS is right for
							you.
						</p>
					</Accordion>
					<Accordion title='Who should not take LUPKYNIS?'>
						<p>
							People taking cyclophosphamide should not take LUPKYNIS. If
							you are pregnant or are planning to be pregnant, are currently
							breastfeeding or are planning to breastfeed, talk to your
							doctor about your options before starting your treatment plan.
							LUPKYNIS should not be taken with medicines known as strong
							CYP3A4 inhibitors such as ketoconazole, itraconazole, and
							clarithromycin. Do not take LUPKYNIS if you are allergic to
							voclosporin or any ingredients in LUPKYNIS.
						</p>
					</Accordion>
					<Accordion title='What does LUPKYNIS do?'>
						<p>
							LUPKYNIS helps stop the attack of lupus nephritis and helps
							protect the kidneys from further irreversible damage.
						</p>
					</Accordion>
					<Accordion title='How does LUPKYNIS work?'>
						<p>
							LUPKYNIS works to calm your immune system, which is causing
							your body to attack the cells of your kidneys. It helps reduce
							inflammation and protect specific cells of the kidneys to keep
							them functioning.
						</p>
					</Accordion>
					<Accordion title='Was LUPKYNIS studied in diverse populations?'>
						<p>
							LUPKYNIS was tested in clinical studies that involved more
							than 350&nbsp;people across different races and ethnicities.
						</p>
					</Accordion>
				</div>
				<div className='accordions'>
					<h3>Talking to your doctor about lupkynis</h3>
					<Accordion title='When I talk to my doctor about LUPKYNIS, what questions should I ask?'>
						<p>
							We created a downloadable doctor discussion guide so you can
							feel confident and prepared to talk to your doctor about
							LUPKYNIS. Get yours{' '}
							<Anchor
								url='/pdfs/doctor-discussion-guide.pdf'
								target='_blank'>
								here
							</Anchor>
							.
						</p>
					</Accordion>
					<Accordion title='Who do I talk to if I have questions about my treatment?'>
						<p>
							If you have questions specifically about LUPKYNIS or any other
							aspects of your lupus nephritis treatment, you should always
							talk to your doctor. You can also enroll in Aurinia Alliance,
							a support program from Aurinia, the makers of LUPKYNIS, to be
							connected with a dedicated Nurse Case Manager. A personally
							assigned Nurse Case Manager (NCM) will provide the consistency
							and expertise needed to help you navigate your LUPKYNIS
							treatment journey. Sign up{' '}
							<Link to='/registration'>here</Link> to get started. You can
							also enroll by calling 1-833-AURINIA (1-833-287-4642).
						</p>
					</Accordion>
				</div>
				<div className='accordions'>
					<h3>
						Treatment resources through aurinia alliance<sup>&reg;</sup>
					</h3>
					<Accordion title='What is Aurinia Alliance?'>
						<p>
							Your Aurinia Alliance team focuses on providing you with what
							you need every step of the way on your LUPKYNIS treatment
							journey. Whether it's educational tools, help understanding
							your insurance and funding options, or just a friendly ear, we
							understand that your lupus nephritis journey is unique and we
							are here to listen and support you along the way. Your team
							includes one-on-one support from a dedicated Nurse Case
							Manager who will check in with you on a regular basis to
							answer your questions and see how you are doing, and a
							specialty pharmacy that will deliver LUPKYNIS directly to you.
						</p>
						<p>
							Sign up <Link to='/registration'>here</Link>. You can also
							enroll by calling 1-833-AURINIA (1-833-287-4642).
						</p>
					</Accordion>
					<Accordion title='What is a Nurse Case Manager?'>
						<p>
							When you enroll with Aurinia Alliance, you'll be paired with a
							Nurse Case Manager. They will be your dedicated contact
							throughout your LUPKYNIS treatment journey to help you stay
							organized and on track with your treatment. If you're
							interested in enrolling, sign up{' '}
							<Link to='/registration'>here</Link> and a dedicated Nurse
							Case Manager will contact you with more information. You can
							also enroll by calling 1-833-AURINIA (1-833-287-4642).
						</p>
					</Accordion>
					<Accordion title='Will I be able to afford treatment with LUPKYNIS?'>
						<p>
							Everyone’s situation is different, but when you enroll with
							Aurinia Alliance, your Nurse Case Manager will partner with
							you and walk through all the options available to you based on
							your individual coverage.
						</p>{' '}
						<p>
							Aurinia also offers a copay program for eligible commercial
							patients. $0 copay for eligible commercial patients.{' '}
						</p>{' '}
						<p>
							97% of the time*, patients paid less than $10 for their
							LUPKYNIS prescription.{' '}
							<Link to='/registration'>Sign up here</Link> to get started.
							You can also enroll by calling 1-833-AURINIA (1-833-287-4642).
						</p>{' '}
						<p>
							*97% of the shipments of LUPKYNIS delivered cost the patient
							$10 or less. These are patients on commercial insurance,
							Medicaid or Medicare from January 2022 to December 2022.
						</p>
					</Accordion>
				</div>
				<div className='accordions'>
					<h3>Taking lupkynis</h3>
					<Accordion title='How do I take LUPKYNIS?'>
						<p>
							You should always take LUPKYNIS as directed by your doctor. A
							standard dose of LUPKYNIS is 23.7&nbsp;mg (three 7.9&nbsp;mg
							capsules) taken orally twice a day. Do not break, crush, chew,
							or dissolve LUPKYNIS capsules before swallowing. Although this
							is the standard dose, you should always take the recommended
							dose that is prescribed to you by your doctor. Each dose
							should be taken 12&nbsp;hours apart, at the same time each
							day. LUPKYNIS should be taken on an empty stomach, either
							1&nbsp;hour before eating or 2&nbsp;hours after eating, unless
							your doctor tells you otherwise.
						</p>
						<p>
							To maintain the quality of the capsule, it’s important to
							remember to never take your LUPKYNIS capsules out of the
							blister pack until you’re ready to take them and to not put
							LUPKYNIS in another container. If you’re going to be away from
							home when it’s time to take your next dose, plan ahead and
							bring your packaged LUPKYNIS capsules with you.
						</p>
					</Accordion>
					<Accordion title='What do I do if I miss a dose of LUPKYNIS?'>
						<p>
							If you've missed a dose, take it as soon as possible within
							4&nbsp;hours of the dose you missed. If more than 4&nbsp;hours
							have passed since missing your dose, wait until the next
							scheduled time to take the next dose, but do not double the
							dose to make up for the one you missed.
						</p>
					</Accordion>
					<Accordion title='What are the most common side effects while taking LUPKYNIS?'>
						<p>
							Side effects included diarrhea, headache, cough, urinary tract
							infection, stomach pain, heartburn, and loss of hair
							(alopecia). These are not all the possible side effects of
							LUPKYNIS. Talk to your doctor about any side effects you may
							be experiencing. Please see the full list of possible side
							effects in the{' '}
							<Anchor
								url='https://www.auriniapharma.com/lupkynis-prescribing-information'
								target='_blank'>
								Prescribing Information
							</Anchor>
							, including Boxed Warning, and{' '}
							<Anchor
								url='https://www.auriniapharma.com/lupkynis-medication-guide'
								target='_blank'>
								Medication Guide
							</Anchor>
							.
						</p>
					</Accordion>
				</div>
			</div>
		</section>
	</Layout>
);

export default resources;
